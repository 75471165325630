/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:64eb5840-4498-44fc-8979-bc82b731f232",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jmEbgzLNC",
    "aws_user_pools_web_client_id": "23vhvb8664ljahg2j3b4r8b3ij",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api0001",
            "endpoint": "https://8x32d06ztl.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "api0002",
            "endpoint": "https://qxkemk4lk1.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "serviclickhubprod0131124-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
