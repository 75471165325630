import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PolicyIcon from "@material-ui/icons/Policy";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BuildIcon from "@material-ui/icons/Build";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PhoneIcon from "@material-ui/icons/Phone";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Rating from "@material-ui/lab/Rating";
import { API, Storage } from "aws-amplify";
import React, { useEffect, useState, useContext } from "react";
import NumberFormat from "react-number-format";
import { formatRut, formatDate } from "../../functions";
import Loading from "../common/Loading";
import Conditions from "../common/Conditions";
import TicketItemView from "../tickets/TicketItemView";
import TicketDetailView from "../tickets/TicketDetailView";
import ContentCardAccountBase from "./base/ContentCardAccount";
import ContentCardAccountBCI from "./BCI Urgencia/ContentCardAccount";
import ContentCardAccountSB from "./SouthBridge Mascota/ContentCardAccount";
import ContentCardAccountServiclick from "./AsistenciasServiclick/ContentCardAccount";

//Contexts 
import { DesktopContext } from "../../views/Desktop";
import { MobileContext } from "../../views/Mobile";

//Import para constantes de polizas BCI
import { COBERTURAS } from "./BCI Urgencia/constants";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2, 3),
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    minHeight: theme.spacing(4),
  },
  cardIcon: {
    marginRight: theme.spacing(3),
  },
  contactListItem: {
    minHeight: theme.spacing(9),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  container: {
    overflow: "auto",
    height: "calc(100vh - 64px)",
    padding: 0,
  },
}));

const TABS = [
  { key: "detail", label: "Contacto" },
  { key: "tickets", label: "Casos" },
];

const getCobertura = (cod) => {
  let cobertura = null;
  COBERTURAS.forEach(element => {    
    if (element.cod === cod){      
      cobertura = element.label;
    }
  });
  return cobertura;
};

const ContactDetailView = ({ contact, onClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [contactTickets, setContactTickets] = useState([]);
  const [mainTab, setMainTab] = useState(0);
  const [showCard, setShowCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [ticket, setTicket] = useState(null);
  //context
  const { onTicketCreate } = useContext(DesktopContext); 
  const { onTicketCreateMobile } = useContext(MobileContext);

  useEffect(() => {
    const fetchContacto = async () => {
      try {
        setLoading(true);

        const init = {
          queryStringParameters: {
            id: contact.id_contacto,
          },
        };

        const result = await API.get(
          process.env.REACT_APP_HUB_API,
          "/contactos",
          init
        );

        let tickets = [];

        if (result.success) {
          if (result.data && result.data.tickets) tickets = result.data.tickets;                
        } else console.warn("fetchContacto", result.error);

        setContactTickets(tickets);
      } catch (err) {
        console.error("fetchContacto", err);
      } finally {
        setLoading(false);
      }
    };

    setTicket(null);
    contact && fetchContacto();
  }, [contact]);

  if (!contact) return null;

  const getAccountCard = (account_name) => {
    const {
      body_contacto,
      nombre_contacto,
      nombre_cuenta,
      tipo_contacto,
      tags_contacto,
    } = contact;
    const {
      rut,
      telefono_contacto,
      correo_contacto,
      datos_bancarios,
    } = body_contacto;

    switch (account_name) {
      case "BCI Sala Urgencia":
        return <ContentCardAccountBCI accountName={nombre_cuenta} contactType={tipo_contacto} contactTags={tags_contacto} contact={contact} getLogo={getLogo}/>
      case "Southbridge-Mascota":
        return <ContentCardAccountSB accountName={nombre_cuenta} contactType={tipo_contacto} contactTags={tags_contacto} contact={contact} getLogo={getLogo}/>
        case "Asistencias-Serviclick":
          return <ContentCardAccountServiclick accountName={nombre_cuenta} contactType={tipo_contacto} contactTags={tags_contacto} contact={contact} getLogo={getLogo}/>
      default:
        return <ContentCardAccountBase  accountName={nombre_cuenta} contactType={tipo_contacto} contactTags={tags_contacto} contact={contact} getLogo={getLogo}/>
    }
  };

  const parse_direccion = (direccion) => {
    if (!direccion){
      return null;
    }
    const comuna = direccion.comuna ? direccion.comuna : null
    const descripcion = direccion.description ? direccion.description : (direccion.descripcion ? direccion.descripcion : direccion.direccion);
    const ciudad = direccion.ciudad;

    var actual_direccion = descripcion ? `${descripcion}` : descripcion
    if (actual_direccion && comuna){
      actual_direccion += `, ${comuna}`
    }
    if (actual_direccion && ciudad){
      actual_direccion += `, ${ciudad}`
    }
    return actual_direccion;
  }

  const handleClick = (option) => {
    setShowCard(true);

    const {
      body_contacto,
      nombre_contacto,
      nombre_cuenta,
      tipo_contacto,
      tags_contacto,
      config_cuenta,
    } = contact;
    const {
      rut,
      telefono_contacto,
      correo_contacto,
      datos_bancarios,
      direccion,
      cobertura,
    } = body_contacto;

    const actual_direccion = parse_direccion(direccion);
    const actual_cobertura = getCobertura(cobertura);
    switch (option) {
      case "details":
        setSelectedCard(
          <ContentCardDetails
            contactRut={rut}
            contactName={nombre_contacto}
            contactType={tipo_contacto}
            contactDireccion={actual_direccion}
            contactCobertura={actual_cobertura}
          />
        );
        break;
      case "account":
        setSelectedCard(
          getAccountCard({nombre_cuenta})
        );
        break;
      case "contact":
        setSelectedCard(
          <ContentCardContact
            contactPhone={telefono_contacto}
            contactMail={correo_contacto}
          />
        );
        break;
      case "bank":
        datos_bancarios &&
          setSelectedCard(
            <ContentCardBank
              accountType={datos_bancarios.tipo_cuenta.label}
              accountNumber={datos_bancarios.numero_cuenta}
              bankName={datos_bancarios.banco.label}
            />
          );
        break;
      case "poliza":
            Storage.get(config_cuenta.poliza , { download: true })
            .then(result => {
              try {
                const pdf_poliza = URL.createObjectURL(result.Body);
                //setPoliza(pdf_poliza);
                // if (mobile) {
                //   window.open(pdf_poliza);          
                // }
                window.open(pdf_poliza);          
              } catch (error) {
                console.log("downloadFile", error);                
              }
            })
            .catch(err => console.log(err));  
      default:
        setShowCard(false);
        break;
    }
  };

  const handleNewTicketButton = () => {   
    if (onTicketCreateMobile){
      onTicketCreateMobile(contact);  
    }else{
      onTicketCreate(contact);
    }    
  };

  //modal para poliza en detalle de contacto
  // const modal_poliza = () => {
  //   return (      
  //       <iframe          
  //         src={poliza}
  //         type="application/pdf"
  //         width="1000"
  //         height="800"
  //       >          
  //       </iframe>
  //   );
  // };

//   const getPoliza = () => {
//     Storage.get(actualTask.body_ticket.poliza , { download: true })
//     .then(result => {
//       try {
//         const pdf_poliza = URL.createObjectURL(result.Body);
//         //setPoliza(pdf_poliza);
//         if (mobile) {
//           window.open(pdf_poliza);          
//         }          
//       } catch (error) {
//         console.log("downloadFile", error);
//         setPoliza(null);          
//       }
//     })
//     .catch(err => console.log(err));  
// };



  const TabPanel = ({ value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tarea-tab-${index}`}
    >
      {value === index && value === 0 ? (
        <>
          <Hidden mdUp>
            <TabContentDetailMobile />
          </Hidden>
          <Hidden smDown>
            <TabContentDetail />
          </Hidden>
        </>
      ) : (
        <TabContentTickets />
      )}
    </div>
  );

  const TabContentDetail = () => {
    const {
      estado_contacto,
      body_contacto,
      nombre_contacto,
      nombre_cuenta,
      tipo_contacto,
      tags_contacto,
      rating  
    } = contact;
    const {
      rut,
      telefono_contacto,
      telefono,
      celular,
      correo_contacto,
      datos_bancarios,
      correo,
      direccion,
      cobertura,
    } = body_contacto;

    const isPrestador = tipo_contacto.includes("prestador");
    const actual_direccion = parse_direccion(direccion);
    const actual_cobertura = getCobertura(cobertura);
    
    return (
      <Container>
        <ContentCardDetails
          contactRut={rut}
          contactName={nombre_contacto}
          contactType={tipo_contacto}
          contactDireccion={actual_direccion}
          contactCobertura={actual_cobertura}
        />
        {nombre_cuenta && (getAccountCard(nombre_cuenta))}
        <ContentCardContact
          contactPhone={telefono_contacto || telefono || celular}
          contactMail={correo_contacto || correo}
        />
        {datos_bancarios && (
          <ContentCardBank
            accountType={datos_bancarios.tipo_cuenta.label}
            accountNumber={datos_bancarios.numero_cuenta}
            bankName={datos_bancarios.banco.label}
          />
        )}
        {isPrestador && <ContentCardRating contactRating={rating} />}
        <ListItem divider className={classes.contactListItem}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleNewTicketButton}
          disabled={estado_contacto == 0 ? true : false}
        >
          Nueva Tarea
        </Button>
      </ListItem>   
      </Container>
    );
  };

  const getLogo = (cuenta) => {
    let srcImg;

    switch (cuenta) {
      case "banigualdad":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banigualdad.png`;
        break;
      case "vias-chile":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_viaschile.png`;
        break;
      case "autofin":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_autofin.png`;
        break;
      case "BCI Urgencia":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_bci.png`;
        break;
      case "banco-estado-hogar":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banco-estado.jpg`
        break;
      case "asistencia-apoyo-medico":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_apoyo-medico.jpg`
        break;
      case "ripley-sura":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_ripley-sura.jpg`;
        break;
      case "southbridge-mascota":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_southbridge-mascota.jpg`;
        break;
      default:
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`;
        break;
    }

    return srcImg;
  };

  const TabContentDetailMobile = () => {
    const {
      estado_contacto,
      body_contacto,
      nombre_contacto,
      nombre_cuenta,
      tipo_contacto,
      tags_contacto,
      rating,
      config_cuenta
    } = contact;
    const {
      rut,
      telefono_contacto,
      correo_contacto,
      datos_bancarios,
      cobertura,
    } = body_contacto;

    let showRating = false;
    const actual_cobertura = getCobertura(cobertura);
    let avatar = (
      <ListItemAvatar>
        <Avatar src={getLogo(contact.slug_cuenta)} />
      </ListItemAvatar>
    );
    let title = nombre_cuenta;
    let subtitle = (
      <>
        {contact.body_contacto.fecha_nacimiento && (
          <>{`Fecha de nacimiento: ${formatDate(
            contact.body_contacto.fecha_nacimiento
          )}`}</>
        )}        
        {contact.body_contacto.fecha_otorgamiento && (
          <>
            <br />
            {`Fecha de otorgamiento de póliza: ${formatDate(
              contact.body_contacto.fecha_otorgamiento
            )}`}
          </>
        )}
        {contact.body_contacto.fecha_termino && (
          <>
            <br />
            {`Fecha de vencimiento de póliza: ${formatDate(
              contact.body_contacto.fecha_termino
            )}`}
          </>
        )}
        {contact.body_contacto.cobertura && (
          <>
            <br />
            {`Poliza: ${
              actual_cobertura
            }`}
          </>
        )}
      </>
    );

    if (tipo_contacto.includes("prestador")) {
      showRating = true;
      avatar = (
        <ListItemIcon>
          <BuildIcon color="disabled" />
        </ListItemIcon>
      );
      title = tags_contacto && tags_contacto.join(" , ");
      subtitle = nombre_cuenta;
    }

    return (
      <List>
        <ListItem
          divider
          className={classes.contactListItem}
          button
          onClick={() => handleClick("details")}
        >
          <ListItemIcon>
            <FolderSharedIcon color="disabled" />
          </ListItemIcon>
          <ListItemText
            className={classes.textCapitalize}
            primary={nombre_contacto}
            secondary={
              <>
                <div>{formatRut(rut)}</div>
                <div>{tipo_contacto}</div>
              </>
            }
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>

        <ListItem
          divider
          className={classes.contactListItem}
          button
          onClick={() => handleClick("account")}
        >
          {avatar}
          <ListItemText
            primary={title}
            secondary={subtitle}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>

        {telefono_contacto && (
          <ListItem
            divider
            className={classes.contactListItem}
            button
            onClick={() => handleClick("contact")}
          >
            <ListItemIcon>
              <PhoneIcon color="disabled" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <NumberFormat
                  value={telefono_contacto}
                  format="+56 9 #### ####"
                  displayType={"text"}
                />
              }
            />
          </ListItem>
        )}

        {correo_contacto && (
          <ListItem
            divider
            className={classes.contactListItem}
            button
            onClick={() => handleClick("contact")}
          >
            <ListItemIcon>
              <AlternateEmailIcon color="disabled" />
            </ListItemIcon>
            <ListItemText secondary={correo_contacto} />
          </ListItem>
        )}

        {config_cuenta.poliza && (
          <ListItem
            divider
            className={classes.contactListItem}
            button
            onClick={() => handleClick("poliza")}
          >
            <ListItemIcon>
              <PolicyIcon color="disabled" />
            </ListItemIcon>
            <ListItemText secondary="Poliza" />
          </ListItem>
        )}
        
        {datos_bancarios && (
          <ListItem
            divider
            className={classes.contactListItem}
            button
            onClick={() => handleClick("bank")}
          >
            <ListItemIcon>
              <AccountBalanceIcon color="disabled" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                  >
                    {datos_bancarios.tipo_cuenta.label}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                  >
                    {datos_bancarios.numero_cuenta}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                  >
                    {datos_bancarios.banco.label}
                  </Typography>
                </>
              }
              disableTypography
            />
          </ListItem>
        )}

        {showRating && (
          <ListItem divider className={classes.contactListItem}>
            <ListItemIcon>
              <ThumbUpIcon color="disabled" />
            </ListItemIcon>
            <ListItemText
              primary={<Rating precision={0.5} value={rating} readOnly />}
              disableTypography
            />
          </ListItem>
        )}

      <ListItem divider className={classes.contactListItem}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleNewTicketButton}
          disabled={estado_contacto == 0 ? true : false}
        >
          Nueva Tarea
        </Button>
      </ListItem>          
      </List>
    );
  };

  const TabContentTickets = () => (
    <>
      <Container className={classes.content}>
        <Typography variant="h6" component="p" color="textPrimary">
          {contactTickets && contactTickets.length > 1
            ? `${contactTickets.length} casos`
            : `${contactTickets.length} caso`}
        </Typography>
      </Container>
      {contactTickets && (
        <>
          <Hidden mdUp>
            <List>
              {contactTickets.map((ticket, i) => (
                <TicketItemView
                  key={`contact-ticket-${i}`}
                  ticket={ticket}
                  onItemClick={(ticket) => {
                    setTicket(ticket);
                  }}
                />
              ))}
            </List>
          </Hidden>
          <Hidden smDown>
            <Container>
              <Grid container spacing={2}>
                {contactTickets.map((ticket, i) => (
                  <TicketItemView
                    key={`contact-ticket-${i}`}
                    variant="card"
                    ticket={ticket}
                    onItemClick={(ticket) => {
                      setTicket(ticket);
                    }}
                  />
                ))}
              </Grid>
            </Container>
          </Hidden>
        </>
      )}
    </>
  );

  const ContentCardDetails = ({ contactRut, contactName, contactType, contactDireccion, contactCobertura }) => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardTitle}>
            <FolderSharedIcon className={classes.cardIcon} color="disabled" />
            <Typography>Datos Personales</Typography>
          </div>

          <Divider className={classes.divider} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              Nombre
            </Grid>
            <Grid className={classes.textCapitalize} item xs={6}>
              {contactName}
            </Grid>
            {contactRut && (
              <>
                <Grid item xs={6}>
                  Rut
                </Grid>
                <Grid item xs={6}>
                  {formatRut(contactRut)}
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              Tipo
            </Grid>
            <Grid className={classes.textCapitalize} item xs={6}>
              {contactType}
            </Grid>
            {contactDireccion && (
              <>
                <Grid item xs={6}>
                  Dirección
                </Grid>
                <Grid item xs={6}>
                  {contactDireccion}
                </Grid>
              </>
            )}
            {contactCobertura && (
              <>
                <Grid item xs={6}>
                  Cobertura
                </Grid>
                <Grid item xs={6}>
                  {contactCobertura}
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };



  const ContentCardContact = ({ contactPhone, contactMail }) => (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <PhoneIcon className={classes.cardIcon} color="disabled" />
          <Typography>Datos Contacto</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Telefono
          </Grid>
          <Grid item xs={6}>
            {contactPhone ? (
              <Link href={"tel:+569" + contactPhone}>
                <NumberFormat
                  value={contactPhone}
                  format="+56 9 #### ####"
                  displayType={"text"}
                />
              </Link>
            ) : (
              "No existe registro"
            )}
          </Grid>
          <Grid item xs={6}>
            Correo
          </Grid>
          <Grid item xs={6}>
            {contactMail ? (
              <Link href={"mailto:" + contactMail}>{contactMail}</Link>
            ) : (
              "No existe registro"
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const ContentCardBank = ({ accountType, accountNumber, bankName }) => (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <AccountBalanceIcon className={classes.cardIcon} color="disabled" />
          <Typography>Datos Bancarios</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Tipo cuenta
          </Grid>
          <Grid item xs={6}>
            {accountType}
          </Grid>
          <Grid item xs={6}>
            Numero de cuenta
          </Grid>
          <Grid item xs={6}>
            {accountNumber}
          </Grid>
          <Grid item xs={6}>
            Banco
          </Grid>
          <Grid item xs={6}>
            {bankName}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const ContentCardRating = ({ contactRating }) => (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <ThumbUpIcon className={classes.cardIcon} color="disabled" />
          <Typography>Evaluacion</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Rating precision={0.5} value={contactRating} readOnly />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      {ticket ? (
        <TicketDetailView ticket={ticket} onClose={() => setTicket(null)} />
      ) : (
        <>
          <AppBar position="sticky">
            <Toolbar>
              {onClose && (
                <IconButton
                  className={classes.backButton}
                  edge="start"
                  color="inherit"
                  onClick={onClose}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Typography className={classes.title} variant="h6" component="p">
                {contact.nombre_contacto}
              </Typography>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {loading ? (
            <Loading />
          ) : (
            <Container className={classes.container}>
              <AppBar position="sticky">
                <Tabs
                  value={mainTab}
                  variant="fullWidth"
                  onChange={(_, newValue) => setMainTab(newValue)}
                  aria-label="contact detail tabs"
                >
                  {TABS.map((item, i) => (
                    <Tab key={`tab-item-${i}`} label={item.label} />
                  ))}
                </Tabs>
              </AppBar>
              {TABS.map((_, i) => (
                <TabPanel key={`tab-panel-${i}`} value={mainTab} index={i} />
              ))}
            </Container>
          )}
          <Hidden mdUp>
            <Drawer
              anchor={"bottom"}
              open={showCard}
              onClose={() => setShowCard(false)}
            >
              {selectedCard}
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};

export default ContactDetailView;
