import React, { useState, useCallback, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";
import Loading from "../common/Loading";
import NumberFormat from "react-number-format";
import { formatRut } from "../../functions";
import Button from "@material-ui/core/Button";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";

const useStyles = makeStyles(theme => ({
  centerGrid: {
    overflow: "hidden",
    textAlign: "center",
    height: "calc(100vh - 64px)"
  },
  root: {
    width: "100%",
    margin: theme.spacing(0, 2, 2, 2)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  card: {
    margin: theme.spacing(2)
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  }
}));

const Tickets = () => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState("");
  const [rows, setRows] = useState([]);
  const order = "desc";
  const orderBy = "folio";
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  const fetchTickets = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          cuenta: account.id_cuenta,
          fecha: date
        }
      };

      //const result = await API.get(process.env.REACT_APP_HUB_API, "/tickets", init);
      const result = await API.get(process.env.REACT_APP_HUB_API, "/reportes/tickets", init);

      if (result.success) {
        const items = [];

        if (result.data) {
          for (let i = 0; i < result.data.length; i++) {
            const ticket = result.data[i];

            // items.push(
            //   createData(
            //     formatDate(ticket.body_ticket.fecha),
            //     ticket.folio_ticket.toString(),
            //     ticket.body_ticket.asegurado.nombre_contacto,
            //     formatRut(ticket.body_ticket.asegurado.body_contacto.rut),
            //     ticket.tipo_ticket.pipeline.pasos_pipeline[ticket.estado_ticket],
            //     getMonto(ticket),
            //     getMontoNum(ticket)
            //   )
            // );
            items.push(ticket);
          }
        }

        setRows(items);
      }
    } catch (err) {
      console.error("fetchTickets", err);
    }

    setLoading(false);
  }, [date]);

  const fetchDates = useCallback(async () => {
    try {
      setLoading(true);

      const init = {
        queryStringParameters: {
          cuenta: account.id_cuenta
        }
      };
      const result = await API.get(process.env.REACT_APP_HUB_API, "/reportes/tickets-dates", init);
      if (result.success) {
        if (result.data) {
          setDates(result.data);
        }
      }
    } catch (err) {
      console.warn("fetchDates", err);
    } finally {
      setLoading(false);
    }
  }, [account]);

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);

      const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

      if (result.success) {
        if (result.data) setAccounts(result.data);
      } else console.warn("fetchAccounts", result.error);
    } catch (err) {
      console.error("fetchAccounts", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) {
      //fetchTickets();
      fetchDates();
      setDate('');
      setDates([]);
      setRows([]);
    }
    else {
      fetchAccounts();      
    }
    //account ? fetchTickets() : fetchAccounts();
  }, [account, fetchDates, fetchAccounts]);

  useEffect(() => {
    if (account && date){
      fetchTickets();
    }
  }, [date, fetchTickets]);

  const getMonto = row => {
    let monto;

    if (row.body_ticket.gestionar_asistencia && row.body_ticket.liquidar_siniestro) {
      monto = (
        <NumberFormat
          prefix={"$ "}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          value={row.body_ticket.liquidar_siniestro.monto}
        />
      );
    }

    if (row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "Pendiente";
    }

    if (!row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "No Aplica";
    }

    return monto;
  };

  const getMontoNum = row => {
    let monto;
    if (row.body_ticket.gestionar_asistencia && row.body_ticket.liquidar_siniestro) {
      monto = row.body_ticket.liquidar_siniestro.monto.toString();
    }
    if (row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "Pendiente";
    }

    if (!row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "No Aplica";
    }
    return monto;
  };

  function createData(fecha, folio, asegurado, rut, estado, monto, monto_num) {
    return { fecha, folio, asegurado, rut, estado, monto, monto_num };
  }

  const formatDate = date => {
    const formattedDate = new Date(date);

    return formattedDate.toLocaleString("es-CL");
  };
  const formatOnlyDate = date => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("es-CL");
  };

  const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: "1"
    }
  }));

  const defaultHeadCells = [
    { id: "fecha", numeric: false, disablePadding: false, label: "Fecha" },
    { id: "folio", numeric: false, disablePadding: false, label: "Caso" },
    { id: "nombre", numeric: false, disablePadding: false, label: "Asegurado" },
    { id: "rut", numeric: false, disablePadding: false, label: "Rut" },
    { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
    { id: "monto", numeric: false, disablePadding: false, label: "Monto" }
  ];
  const bciHeadCells = [
    { id: "fecha", numeric: false, disablePadding: false, label: "Fecha" },
    { id: "tipo_poliza", numeric: false, disablePadding: false, label: "T. Poliza" },
    { id: "no_poliza", numeric: false, disablePadding: false, label: "N. Poliza" },
    { id: "rut", numeric: false, disablePadding: false, label: "Rut" },    
    { id: "f_nacimiento", numeric: false, disablePadding: false, label: "F. Nacimiento" },
    { id: "t_siniestro", numeric: false, disablePadding: false, label: "T. Servicio" },
    { id: "monto", numeric: false, disablePadding: false, label: "Monto" },
    { id: "contacto", numeric: false, disablePadding: false, label: "Contacto" },
    { id: "prevision", numeric: false, disablePadding: false, label: "Previsión" },
  ];

  const headCells = () => {
    switch (account.slug_cuenta) {
      case "BCI Urgencia":
        return bciHeadCells;
      default:
        return defaultHeadCells;
    }
  };

  const defaultExcelFile = () => {
    return (<ExcelFile filename={`casos_${account.slug_cuenta}_${date}`} element={<Button
      variant="contained"
      color="secondary"
      size="small"
    >
      Exportar
    </Button>}>
      <ExcelSheet data={rows} name="Casos">
        <ExcelColumn label="Fecha" value="fecha" />
        <ExcelColumn label="Folio" value="folio" />
        <ExcelColumn label="Asegurado" value="nombre" />
        <ExcelColumn label="Rut" value="rut" />
        <ExcelColumn label="Estado" value="estado" />
        <ExcelColumn label="Monto" value="monto" />
      </ExcelSheet>
    </ExcelFile>);
  };
  const bciExcelFile = () => {
    return (<ExcelFile filename={`casos_${account.slug_cuenta}_${date}`} element={<Button
      variant="contained"
      color="secondary"
      size="small"
    >
      Exportar
    </Button>}>
      <ExcelSheet data={rows} name="Casos">
        <ExcelColumn label="Fecha" value="fecha" />
        <ExcelColumn label="Tipo Poliza" value="tipo_poliza" />
        <ExcelColumn label="N. Poliza" value="no_poliza" />
        <ExcelColumn label="Rut" value="rut" />
        <ExcelColumn label="Fecha Nacimiento" value="f_nacimiento" />
        <ExcelColumn label="Tipo Siniestro" value="t_siniestro" />
        <ExcelColumn label="Monto" value="monto" />
        <ExcelColumn label="Contacto" value="contacto" />
        <ExcelColumn label="Previsión" value="prevision" />
      </ExcelSheet>
    </ExcelFile>);
  };

  const getExcelFile = () => {
    switch (account.slug_cuenta) {
      case "BCI Urgencia":
        return bciExcelFile();
      default:
        return defaultExcelFile();
    }
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();

    return (
      <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Casos
        </Typography>
        <Typography variant="body1" component="div">
          {new Date().toLocaleDateString("es-CL", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Typography>
        {rows.length > 0 && (getExcelFile())}

      </Toolbar>
    );
  };

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells().map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = 0;


  //tabla por defecto
  const defaultRow = (row, index, labelId) => {
    return (
      <>
      <TableRow hover role="checkbox" tabIndex={-1} key={`row-${index}`}>
        <TableCell component="th" id={labelId} scope="row">
          {formatDate(row.fecha)}
        </TableCell>
        <TableCell>{row.folio}</TableCell>
        <TableCell>{row.nombre}</TableCell>
        <TableCell>{formatRut(row.rut)}</TableCell>
        <TableCell>{row.estado}</TableCell>
        <TableCell>
          {(!isNaN(row.monto) ? 
          <NumberFormat
          prefix={"$ "}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          value={row.monto}
          />
          : row.monto)}
        </TableCell>
      </TableRow>
      </>
    );
  };
  //tabla para BCI Urgencia
  const bciRow = (row, index, labelId) => {
    return (
      <>
      <TableRow hover role="checkbox" tabIndex={-1} key={`row-${index}`}>
        <TableCell component="th" id={labelId} scope="row">
          {formatDate(row.fecha)}
        </TableCell>
        <TableCell>{row.tipo_poliza}</TableCell>
        <TableCell>{row.no_poliza}</TableCell>
        <TableCell>{formatRut(row.rut)}</TableCell>
        <TableCell>{(row.f_nacimiento ? formatOnlyDate(row.f_nacimiento) : "No aplica")}</TableCell>
        <TableCell>{(row.t_siniestro ? row.t_siniestro : "No aplica")}</TableCell>
        <TableCell>
          {(!isNaN(row.monto) ? 
          <NumberFormat
          prefix={"$ "}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          value={row.monto}
          />
          : row.monto)}
        </TableCell>
        <TableCell>{row.contacto}</TableCell>
        <TableCell>{(row.prevision ? row.prevision : "No aplica")}</TableCell>
      </TableRow>
      </>
    );
  };
  //if de tablas
  const usedRow = (row, index, labelId) => {
    switch (account.slug_cuenta) {
      case "BCI Urgencia":
        return bciRow(row, index, labelId);
      default:
        return defaultRow(row, index, labelId);
    };
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="p">
                Seleccione una cuenta
              </Typography>
              <Select
                fullWidth
                classes={{ selectMenu: classes.select }}
                variant="outlined"
                value={account}
                onChange={e => {
                  setAccount(e.target.value);
                }}
              >
                {accounts.map(item => (
                  <MenuItem key={`item-${item.id_cuenta}`} value={item}>
                    {item.alias_cuenta}
                  </MenuItem>
                ))}
              </Select>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="p">
                Seleccione un mes
              </Typography>
              <Select
                fullWidth
                classes={{ selectMenu: classes.select }}
                variant="outlined"
                value={date}
                onChange={e => {
                  setDate(e.target.value);
                }}
              >
                {dates.map(item => (
                  <MenuItem key={`item-${item}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Loading />
          ) : (
            (account && date) && (
              <>
                <Paper className={classes.paper}>
                  <EnhancedTableToolbar />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return usedRow(row, index, labelId);
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={4} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Tickets;
