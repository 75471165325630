import React, { useContext, useRef, useState, useMemo } from "react";
import { API, Storage } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { AppContext } from "../App";
import { TASK_TYPES } from "./constants";
import Loading from "../common/Loading";
import Conditions from "../common/Conditions";

const useStyles = makeStyles(theme => ({
  marginY: {
    margin: theme.spacing(1, 0)
  },
  marginX: {
    margin: theme.spacing(0, 1)
  },
  title: {
    flexGrow: 1
  },
  taskHeader: {
    padding: theme.spacing(2, 0, 0)
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  h100: {
    height: "calc(100% - 112px)"
  }
}));

const Task = ({ task, onSelectedTask }) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [showDialog, setShowDialog] = useState(false);
  const [data, setData] = useState({ toLastTask: false, saveToTicketBody: false });
  const comentarioRef = useRef();
  const [loading, setLoading] = useState(false);
  const [actualTask, setActualTask] = useState(task);

  const { accountTask, nextTask, lastTask } = useMemo(() => {
    let account = null;
    let accountTask = null;
    let nextTask = null;
    let lastTask = null;

    account = TASK_TYPES.filter(acc => {
      return acc.value === actualTask.slug_cuenta;
    });

    if (account.length === 0) {
      account = TASK_TYPES.filter(acc => {
        return acc.value === "default";
      });
    }

    account = account[0];

    if (account) {
      accountTask =
        Object.values(account).filter(item => {
          if (typeof item !== "object") {
            return null;
          }

          return item.value === actualTask.tipo_tarea;
        })[0] || null;

      if (accountTask) {
        nextTask = Object.values(account).filter(item => {
          if (typeof item !== "object") {
            return null;
          }

          if (Array.isArray(accountTask.next)) {
            return item.value === accountTask.next[0] || item.value === accountTask.next[1];
          }

          return item.value === accountTask.next;
        });
      }

      lastTask = Object.values(account).filter(item => {
        if (typeof item !== "object") {
          return null;
        }

        return item.next === false;
      });
    }
    return { accountTask, nextTask, lastTask };
  }, [task]);

  const createTask = async ({ value, title }) => {
    try {
      const init = {
        body: {
          id: actualTask.id_ticket,
          tipo: value,
          nombre: title,
          body: JSON.stringify({}),
          estado: 0,
          usuario: user.id_usuario
        }
      };
      const result = await API.post(process.env.REACT_APP_HUB_API, "/tickets/tarea", init);

      if (result.success) {
        return { actividad: result.data.actividad, tarea: result.data.tarea };
      } else {
        return { error: result.error };
      }
    } catch (error) {
      return { error };
    }
  };

  const createNextTask = async () => {
    if (data.toLastTask === true) {
      await Promise.all([
        updateTicketStatus({ estado: actualTask.pasos_pipeline.length - 2 }),
        lastTask.map(lastTask => createTask({ value: lastTask.value, title: lastTask.title }))
      ]);

      return true;
    }

    await updateTicketStatus({ estado: actualTask.estado_ticket + 1 });

    if (nextTask[0]) {
      await nextTask.map(nextTask => createTask({ value: nextTask.value, title: nextTask.title }));

      return true;
    }

    return false;
  };

  const updateTask = async ({ estado }) => {
    setLoading(true);

    try {
      const comentario = comentarioRef.current ? comentarioRef.current.value.trim() : "";

      if (data && data.archivos) {
        const processedFiles = [];

        let config = {
          level: "public"
        };

        for (let i = 0; i < data.archivos.length; i++) {
          let file = data.archivos[i];
          const fileIndex = i + 1;
          config.contentType = file.fileType;

          file.name = `${actualTask.tipo_tarea}-${fileIndex}${file.extension}`;
          file.accountId = actualTask.id_cuenta;
          file.taskId = actualTask.id_tarea;
          file.key = `${file.accountId}/tasks/${file.taskId}/${file.name}`;

          await Storage.put(file.key, file.blob, config);
          const s3File = {
            key: file.key,
            name: file.name,
            extension: file.extension,
            size: file.size,
            type: file.type
          };

          processedFiles.push(s3File);
        }

        data.archivos = processedFiles;
      }

      const body = {
        ...data,
        comentario
      };

      const init = {
        body: {
          id: actualTask.id_tarea,
          body: JSON.stringify(body),
          estado,
          actividad: JSON.stringify({
            tipo: "tarea",
            tarea: {
              body_tarea: { ...body },
              body_ticket: actualTask.body_ticket,
              fecha_creacion_tarea: actualTask.fecha_creacion_tarea,
              id_cuenta: actualTask.id_cuenta,
              id_tarea: actualTask.id_tarea,
              id_ticket: actualTask.id_ticket,
              id_usuario_crea_tarea: actualTask.id_usuario_crea_tarea,
              id_usuario_responsable_tarea: actualTask.id_usuario_responsable_tarea,
              nombre_tarea: actualTask.nombre_tarea,
              tipo_tarea: actualTask.tipo_tarea
            }
          }),
          ticket: actualTask.id_ticket,
          responsable: user.id_usuario
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/tarea", init);

      if (result.success) {
        if (data.saveToTicketBody === true) {
          const taskName = accountTask.value.replace("-", "_");

          await updateTicketBody({
            ...actualTask.body_ticket,
            [taskName]: result.data.tarea.body_tarea
          });
        }

        await createNextTask();
        onSelectedTask(null);

        setLoading(false);

        return { actividad: result.data.actividad, tarea: result.data.tarea };
      } else {
        console.warn("updateTask", result.error);
        setLoading(false);
        return { error: result.error };
      }
    } catch (error) {
      console.error("updateTask", error);
      setLoading(false);
      return { error };
    }
  };

  const endTask = async () => {
    await updateTask({ estado: 2 });
    return true;
  };

  const updateTicketStatus = async ({ estado }) => {
    try {
      const init = {
        body: {
          id: actualTask.id_ticket,
          estado,
          actividad: null,
          responsable: user.id_usuario
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/estado", init);

      if (result.success) {
        if (estado === actualTask.pasos_pipeline.length - 1) {
          notificarContacto({
            email: actualTask.body_ticket.asegurado.body_contacto.correo,
            subject: `Notificación de solicitud #${actualTask.folio_ticket}`,
            message: `Su solicitud ha sido cerrada.`
          });
        }

        return { actividad: result.data.actividad };
      } else {
        return { error: result.error };
      }
    } catch (error) {
      return { error };
    }
  };

  const updateTicketBody = async body => {
    try {
      const init = {
        body: {
          id: actualTask.id_ticket,
          body: JSON.stringify(body),
          actividad: null,
          responsable: user.id_usuario
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/body", init);

      if (result.success) {
        const actividad = JSON.parse(result.data.actividad);

        return { actividad };
      } else {
        return { error: result.error };
      }
    } catch (error) {
      return { error };
    }
  };

  const notificarContacto = async ({ email, subject, message }) => {
    if (email && subject && message) {
      try {
        const init = {
          body: {
            email,
            subject,
            message
          }
        };

        const result = await API.post(process.env.REACT_APP_HUB_API, "/contactos/notificar", init);

        if (!result.success) {
          console.log("Task.notificarContacto.result", result);
        }
      } catch (err) {
        console.log("Task.notificarContacto.error", err);
      }
    }
  };

  const TaskClickDialog = () => {
    return (
      <Dialog maxWidth="sm" open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Continuar</DialogTitle>

        <DialogContent>
          <FormLabel component="legend">
            Puedes ingresar un comentario de manera opcional para continuar
          </FormLabel>
          <TextField
            variant="outlined"
            className={classes.marginY}
            fullWidth
            multiline
            rows="3"
            size="small"
            inputRef={comentarioRef}
            InputLabelProps={{
              shrink: true
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button fullWidth disabled={loading} onClick={() => setShowDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button
            fullWidth
            disabled={loading}
            onClick={() => {
              setShowDialog(false);
              endTask();
            }}
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderContent = () => {
    return loading || !accountTask ? (
      <Loading />
    ) : (
      accountTask.component({ task, data, setData, setShowDialog, notificarContacto })
    );
  };

  return (
    <>
      <TaskClickDialog />

      <Hidden mdUp>
        <Container className={classes.marginY}>
          <Typography variant="h6" component="p">
            {actualTask.alias_cuenta}
            {actualTask.config_cuenta && actualTask.config_cuenta.condiciones && (
              <Conditions condiciones={actualTask.config_cuenta.condiciones} />
            )}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
          >{`${actualTask.nombre_ticket} #${actualTask.folio_ticket}`}</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
          >{`${actualTask.body_ticket.asegurado.nombre_contacto}`}</Typography>
        </Container>
        <Divider className={classes.marginY} />
        <Container>{renderContent()}</Container>
      </Hidden>

      <Hidden smDown>
        <Container>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="p">
                {actualTask.alias_cuenta}
                {actualTask.config_cuenta && actualTask.config_cuenta.condiciones && (
                  <Conditions condiciones={actualTask.config_cuenta.condiciones} />
                )}
              </Typography>

              <Divider className={classes.marginY} />
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
              >{`${actualTask.nombre_ticket} #${actualTask.folio_ticket}`}</Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
              >{`${actualTask.body_ticket.asegurado.nombre_contacto}`}</Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Container>
      </Hidden>
    </>
  );
};

export default Task;
